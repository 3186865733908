import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { React, useEffect,lazy, Suspense } from 'react';
import { Ring } from '@uiball/loaders'
{/*
import Nav from "./components/Nav"
import Footer from "./components/Footer"
import ScrollToTop from "./components/ScrollToTop";

import Home from "./pages/Home";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
//import NotFound from "./pages/NotFound";
import Careers from "./pages/Careers"
import About from "./pages/About"
import EvCharging from "./pages/EvCharging";
*/}
const NotFound = lazy(() => import("./pages/NotFound"))
const Nav = lazy(() => import("./components/Nav"))
const Footer= lazy(() => import("./components/Footer"))
const ScrollToTop = lazy(() => import("./components/ScrollToTop"))
const Home = lazy(() => import("./pages/Home"))
const Contact = lazy(() => import("./pages/Contact"))
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"))
const Careers = lazy(() => import("./pages/Careers"))
const About = lazy(() => import("./pages/About"))
const EvCharging = lazy(() => import("./pages/EvCharging"))




function App() {
  const renderLoader = () =><div className="flex justify-center" ><Ring  size={30} color="#231F20" /></div>;
  return (
    <Suspense fallback={renderLoader()}>
      <Router>
        <ScrollToTop />
        <div>
          <Nav />
          <div>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/about">
                <About/>
              </Route> 
              <Route exact path="/ev-charging">
                <EvCharging/>
              </Route> 
              <Route exact path="/contact">
                <Contact />
              </Route>
              <Route exact path="/careers">
                <Careers/>
              </Route>
              <Route exact path="/privacy-policy">
                <PrivacyPolicy />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    </Suspense>
  );
}

export default App;


